export const ASSOCIATION_MODES = {
  CATEGORY: "CATEGORY",
  NARRATIVE: "NARRATIVE",
  FILTER: "FILTER",
  SPOTLIGHT: "SPOTLIGHT",
};

export const APP_STATE_ASSOCIATIONS = {
  CATEGORY: "categories",
  FILTER: "filters",
};

export const SHAPE = "SHAPE";

export const DEFAULT_TAB_ICONS = {
  CATEGORY: "widgets",
  NARRATIVE: "timeline",
  FILTER: "filter_list",
  SHAPE: "change_history",
  SPOTLIGHT: "highlight",
};

export const AVAILABLE_SHAPES = {
  STAR: "STAR",
  DIAMOND: "DIAMOND",
  PENTAGON: "PENTAGON",
  SQUARE: "SQUARE",
  DOT: "DOT",
  BAR: "BAR",
  TRIANGLE: "TRIANGLE",
  HEXAGON: "HEXAGON",
  DASH: "DASH",
  CIRCLE: "CIRCLE",
};

export const POLYGON_CLIP_PATH = {
  STAR: "polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%)",
  DIAMOND: "polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)",
  PENTAGON: "polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%)",
  TRIANGLE: "polygon(50% 0%, 0% 100%, 100% 100%)",
  HEXAGON: "polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%)",
};

export const DEFAULT_CHECKBOX_COLOR = "#ffffff";

export const COLORING_ALGORITHM_MODE = {
  STATIC: "STATIC",
  DYNAMIC: "DYNAMIC",
};

export const ASSOCIATION_TYPES = {
  SINGLE_SELECT: "SINGLE_SELECT",
  DASH: "DASH",
  SOLID: "SOLID",
};

export const DATASHEET_FALSE = "FALSE";

export const SPOTLIGHT_TITLE_LENGTH = 22;

export const LANGUAGE_OPTIONS = {
  EN_US: "en-us",
};

export const EVENT_MARKER_OFFSETS = {
  SQUARE: 7,
  DIAMOND: 10,
  DASH: -1,
};

export const MEDIA_TYPES = {
  VIDEO: "Video",
  IMAGE: "Image",
  TEXT: "Text",
  DOCUMENT: "Document",
  TWITTER: "Twitter",
  UNKNOWN: "Unknown",
};

export const DASH_HEIGHT = 20;
export const DASH_SPOTLIGHT_RADIUS = 6;
