import React from "react";
import * as d3 from "d3";

class TimelineCategories extends React.Component {
  constructor(props) {
    super(props);
    this.grabRef = React.createRef();
    this.state = {
      isInitialized: false,
    };
  }

  componentDidUpdate() {
    if (!this.state.isInitialized) {
      const drag = d3
        .drag()
        .on("start", this.props.onDragStart)
        .on("drag", this.props.onDrag)
        .on("end", this.props.onDragEnd);

      d3.select(this.grabRef.current).call(drag);

      this.setState({ isInitialized: true });
    }
  }

  renderCategory(cat, idx) {
    const { features, dims, defaultCategory } = this.props;
    const isDefaultCategory = cat === defaultCategory;

    const styles = {
      strokeWidth: isDefaultCategory ? 2 : 1,
      opacity: isDefaultCategory ? "0.65" : "0.5",
    };

    // const strokeWidth = isDefaultCategory ? 2 : 1; // dims.trackHeight / (this.props.categories.length + 1)
    if (
      features.GRAPH_NONLOCATED &&
      features.GRAPH_NONLOCATED.categories &&
      features.GRAPH_NONLOCATED.categories.includes(cat)
    ) {
      return null;
    }

    return (
      <>
        <g
          className="tick"
          style={styles}
          transform={`translate(0,${this.props.getCategoryY(cat)})`}
        >
          <line x1={dims.marginLeft} x2={dims.width - dims.width_controls} />
        </g>
        <g
          className="tick"
          opacity="1"
          transform={`translate(0,${this.props.getCategoryY(cat)})`}
        >
          <text x={dims.marginLeft - 5} dy="0.32em">
            {cat}
          </text>
        </g>
      </>
    );
  }

  render() {
    const { dims, categories, fallbackLabel } = this.props;
    const categoriesExist = categories && categories.length > 0;
    const renderedCategories = categoriesExist
      ? categories.map((cat, idx) => this.renderCategory(cat, idx))
      : this.renderCategory(fallbackLabel, 0);

    return (
      <g className="yAxis">
        {renderedCategories}
        <rect
          ref={this.grabRef}
          className="drag-grabber"
          x={dims.marginLeft}
          y={dims.marginTop}
          width={dims.width - dims.marginLeft - dims.width_controls}
          height={dims.contentHeight}
        />
      </g>
    );
  }
}

export default TimelineCategories;
